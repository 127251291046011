<template>
	<el-form
		:model="addForm"
		:rules="rules"
		ref="addForm"
		label-width="82px"
		label-position="left"
		class="demo-form dialog-form"
	>
		<el-form-item label="字典项描述：" prop="description">
			<el-input v-model.trim="addForm.description" placeholder="请输入字典项描述"></el-input>
		</el-form-item>
		<el-form-item label="字典项说明：" prop="remarks">
			<el-input
				type="textarea"
				:autosize="{ minRows: 2 }"
				maxlength="50"
				show-word-limit
				placeholder="请输入字典项说明"
				v-model.trim="addForm.remarks"
			></el-input>
		</el-form-item>
        <el-form-item label="展示值：" prop="label">
			<el-input v-model.trim="addForm.label" placeholder="请输入展示值"></el-input>
		</el-form-item>
        <el-form-item label="选择值：" prop="value">
			<el-input v-model.trim="addForm.value" placeholder="请输入选择值"></el-input>
		</el-form-item>
	</el-form>
</template>
<script>
import { insertDictsItems, updateDictsItems, getDictsItemsById } from "@/api/lib/api.js";
export default {
	props: {
		title: {
			type: String,
			default: ""
		},
		dictId: {
			type: String,
			default: null
        },
        dictItemId:{
            type: String,
			default: null
        },
        type:{
            type: String,
			default: null
        },
		/* loadData: {
			type: Function,
			default: null
		} */
	},
	data() {
		return {
			addForm: {
				description: "",
                remarks: "",
                label:"",
                value:""
			},
			rules: {
				description: [
					{
						required: true,
						message: "请输入字典描述",
						trigger: "blur"
					}
				],
				remarks: [
					{
						required: true,
						message: "请输入字典说明",
						trigger: "blur"
					}
				]
			},
			companyList: []
		};
	},
	methods: {
		// 新增编辑字典
		onSave() {
			this.$refs.addForm.validate(valid => {
				if (valid) {
					let data = {
						...this.addForm,
						type: this.type,
						dictId: this.dictId
					};
					if (this.title.indexOf("新增") !== -1) {
						//data.status = 0;
						insertDictsItems(data).then(res => {
							console.log("新增字典项", res);
							if (res.code === 0) {
								this.$message.success("新增成功");
								//this.loadData();
							} else {
								this.$message.error("新增失败");
							}
						});
					} else {
						data = {
							...data,
							id: this.dictItemId
						};
						updateDictsItems(data).then(res => {
							if (res.code === 0) {
								this.$message.success("修改成功");
								//this.loadData();
							} else {
								this.$message.error("修改失败");
							}
						});
					}
					this.$emit("onDialogClose");
				}
			});
		}
	},
	created() {
		if (this.dictItemId !== null) {
			getDictsItemsById(this.dictItemId).then(res => {
				if (res.code === 0) {
					this.addForm = { ...res.data };
				}
			});
		}
	},
	mounted() {}
};
</script>