<template>
	<el-form
		:model="addForm"
		:rules="rules"
		ref="addForm"
		label-width="82px"
		label-position="left"
		class="demo-form dialog-form"
	>
		<el-form-item label="字典描述：" prop="description">
			<el-input v-model.trim="addForm.description" placeholder="请输入字典描述"></el-input>
		</el-form-item>
		<el-form-item label="字典说明：" prop="remarks">
			<el-input
				type="textarea"
				:autosize="{ minRows: 2 }"
				maxlength="50"
				show-word-limit
				placeholder="请输入字典说明"
				v-model.trim="addForm.remarks"
			></el-input>
		</el-form-item>
		<el-form-item label="所属系统：" prop="sys">
			<el-select v-model.trim="addForm.sys" placeholder="请选择">
				<el-option key="0" label="0" :value="0"></el-option>
				<el-option key="1" label="1" :value="1"></el-option>
			</el-select>
		</el-form-item>
		<el-form-item label="字典类型：" prop="type">
			<el-input v-model.trim="addForm.type" placeholder="请输入字典类型"></el-input>
		</el-form-item>
	</el-form>
</template>
<script>
import { insertDicts, updateDicts, getDictsById } from "@/api/lib/api.js";
export default {
	props: {
		title: {
			type: String,
			default: ""
		},
		dictId: {
			type: String,
			default: null
		},
		loadData: {
			type: Function,
			default: null
		}
	},
	data() {
		return {
			addForm: {
				id: "",
				description: "",
				remarks: "",
				sys: "",
				type: ""
			},
			rules: {
				description: [
					{
						required: true,
						message: "请输入字典描述",
						trigger: "blur"
					}
				],
				remarks: [
					{
						required: true,
						message: "请输入字典说明",
						trigger: "blur"
					}
				]
			},
			companyList: []
		};
	},
	methods: {
		// 新增编辑字典
		onSave() {
			this.$refs.addForm.validate(valid => {
				if (valid) {
					let data = {
						...this.addForm
					};
					if (this.title.indexOf("新增") !== -1) {
						//data.status = 0;
						insertDicts(data).then(res => {
							console.log("新增字典", res);
							if (res.code === 0) {
								this.$message.success("新增成功");
								this.loadData();
							} else {
								this.$message.error("新增失败");
							}
						});
					} else {
						data = {
							...data,
							id: this.dictId
						};
						updateDicts(data).then(res => {
							if (res.code === 0) {
								this.$message.success("修改成功");
								this.loadData();
							} else {
								this.$message.error("修改失败");
							}
						});
					}
					this.$emit("onDialogClose");
				}
			});
		}
	},
	created() {
		if (this.dictId !== null) {
			getDictsById(this.dictId).then(res => {
				if (res.code === 0) {
					this.addForm = { ...res.data };
				}
			});
		}
	},
	mounted() {}
};
</script>