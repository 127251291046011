 <template>
  <div class="dict-management" ref="dictManagement">
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="62px"
      >
        <el-form-item label="状态:">
          <el-select v-model.trim="form.status" placeholder="请选择">
            <el-option key="0" label="正常" :value="0"></el-option>
            <el-option key="1" label="锁定" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSearch">查询</el-button>
          <el-button type="primary" size="small" @click="onAdddict">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      stripe
      :height="tableHeight"
    >
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="id" label="编号"></el-table-column>
      <el-table-column prop="description" label="描述"></el-table-column>
      <el-table-column prop="remarks" label="说明"></el-table-column>
      <!-- <el-table-column prop="createBy" label="创建人"></el-table-column> -->
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column prop="sys" label="所属系统"></el-table-column>
      <el-table-column prop="type" label="类型"></el-table-column>
      <el-table-column prop="status" label="是否锁定字典状态" width="150">
            <template slot-scope="scope">
            <el-switch
                @change="changeStatus($event,scope.row)"
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
            ></el-switch>
            </template>
        </el-table-column>
      <el-table-column label="操作" width="380">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-view"
            type="text"
            size="small"
            sort="info"
            @click="onSearchItem(scope.row)"
          >查看字典项</el-button>
          <el-button
            icon="el-icon-edit-outline"
            type="text"
            size="small"
            sort="primary"
            @click="onUpdateDict(scope.row)"
          >编辑</el-button>
          <el-button
            icon="el-icon-plus"
            type="text"
            size="small"
            sort="authorize"
            @click="onAddItem(scope.row)"
          >新增字典项</el-button>

          <el-button
            icon="el-icon-delete"
            type="text"
            size="small"
            sort="danger"
            @click="onDeleteDict(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <el-dialog :title="title" v-if="dialogVisible" :visible.sync="dialogVisible" width="30%">
      <div>
        <div class="body"></div>
        <addDict
          ref="addDict"
          :dictId="dictId"
          :title="title"
          @onDialogClose="onDialogClose"
          :loadData="onSearch"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="onSave"
        >{{ title.indexOf("新增") !== -1 ? "新增" : "保存"}}</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="titleItem" v-if="dialogVisibleItem" :visible.sync="dialogVisibleItem" width="30%">
      <div>
        <div class="body"></div>
        <addDictItem
          ref="addDictItem"
          :dictId="dictId"
          :title="titleItem"
          :type="type"
          :dictItemId="dictItemId"
          @onDialogClose="onDialogCloseItem"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisibleItem = false">取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="onSaveItem"
        >{{ title.indexOf("新增") !== -1 ? "新增" : "保存"}}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="titleList" v-if="dialogVisibleList" :visible.sync="dialogVisibleList" width="74%">
      <div>
        <div class="body"></div>
        <dictItemsList
          ref="dictItemsList"
          :type="type"
          @onDialogClose="onDialogCloseList"
          @onEditDictItems="onEditDictItems"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisibleList = false">关闭</el-button>
      </span>
    </el-dialog>
    
  </div>
</template>

  <script>
import addDict from "./components/addDict.vue";
import addDictItem from "./components/addDictItem.vue"
import dictItemsList from "./components/dictItemsList.vue";
import { getDictsPage, deleteDicts,updateDictsStatus,updateDictsItemStatus } from "@/api/lib/api.js";
export default {
  name: "dictManagement",
  components: {
    addDict,
    addDictItem,
    dictItemsList
  },
  data () {
    return {
      tableHeight: 0,
      total: 1,
      tableData: [
        {
          id: "",
          description: "",
          remarks: "",
          createBy: "",
          createTime: "",
          sys: "",
          type: "",
          status: "",
        },
      ],
      form: {
        status: 0,
        currentPage: 1,
        pageSize: 10,
      },
      //multipleSelection: [],
      title: "",
      dictId: null,
      dialogVisible: false,
      dialogVisibleItem:false,
      dialogVisibleList:false,
      titleItem:"",
      type:"",
      dictItemId:null,
      titleList:""
    };
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.dictManagement.clientHeight;
      let formHeight = this.$refs.form.clientHeight;
      let paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight - 16 - formHeight - paginationHeight - 10 - 16 - 35;
    },
    // 点击查询
    onSearch () {
      let data = {
        status: this.form.status,
        current: this.form.currentPage,
        size: this.form.pageSize,
      };
      getDictsPage(data).then((res) => {
        //console.log("字典信息", res);
        if (res.code === 0) {
          this.tableData = res.data.records;
          this.total = res.data.total;
          if (!res.data.records.length && this.form.currentPage !== 1) {
            //如果当前页的数据为0则设置页面为前一页 但是确保当前页不为1
            self.form.currentPage -= 1;
            self.onSearch(this.form);
          }
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error(res.message);
        }
      });
    },
    //新增
    onAdddict () {
      this.title = "新增字典";
      this.dialogVisible = true;
    },
    //编辑
    onUpdateDict (row) {
      //console.log("gengxing", row);
      this.title = "修改字典";
      this.dictId = row.id;
      this.dialogVisible = true;
    },
    //删除
    onDeleteDict (row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "确定删除 "),
          h("i", { style: "color: #059370" }, row.name),
          h("span", null, " ？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteDicts(row.id).then((res) => {
            if (res.code === 0) {
              this.$message.success("删除成功");
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除",
          });
        });
    },
    //查看字典项
    onSearchItem (row) {
         this.type=row.type
        this.dialogVisibleList = true;
        this.titleList="查看字典项";
     },
    //新增字典项
    onAddItem (row) { 
        this.titleItem = "新增字典项";
        this.dictId=row.id;
        this.type=row.type
        this.dialogVisibleItem = true;
    },
    onSave () {
      this.$refs.addDict.onSave();
    },
    onSaveItem(){
      this.$refs.addDictItem.onSave();
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page;
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    // 弹窗关闭时的回调
    onDialogClose () {
      this.dialogVisible = false;
    },
    //
    onDialogCloseItem(){
      this.dialogVisibleItem = false;
    },
    onDialogCloseList(){
        this.dialogVisibleList = false;
    },
    onEditDictItems(data){
        console.log(data);
        if(data){
            let dataJson=JSON.parse(data)
            this.titleItem = "编辑字典项";
            this.dictId=dataJson.dictId;
            this.type=dataJson.type;
            this.dictItemId=dataJson.id;
            this.dialogVisibleItem = true;
        }
    },
    // 修改用户状态
    changeStatus (e, row) {
      //console.log('ghggjgghgjh',row.id)
      let message = e === 1 ? "锁定" : "启用";
      updateDictsStatus({ id: row.id, status: e }).then((res) => {
        if (res.code === 0) {
          this.$message.success(`${message}成功`);
        } else {
          this.$message.error(`${message}失败`);
          row.status = e === 1 ? 0 : 1;
          //console.log(111,row)
        }
      });
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
    this.onSearch();
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>
<style lang="scss" scoped>
.dict-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  .avatarImg {
    vertical-align: middle;
    border-radius: 50%;
  }
}

.theme-light {
  .dict-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>