<template>
	<div class="dictItem-management" ref="dictItemManagement">
		<el-table ref="multipleTable" :data="tableData" style="width: 100%" stripe>
			<el-table-column prop="id" label="编号"></el-table-column>
			<el-table-column prop="description" label="描述"></el-table-column>
			<el-table-column prop="remarks" label="说明"></el-table-column>
			<el-table-column prop="label" label="展示值"></el-table-column>
			<el-table-column prop="value" label="选择值"></el-table-column>
			<el-table-column prop="createTime" label="创建时间"></el-table-column>
			<el-table-column label="操作" width="190">
				<template slot-scope="scope">
					<el-button
						icon="el-icon-edit-outline"
						type="text"
						size="small"
						sort="primary"
						@click="onUpdateDictItem(scope.row)"
					>编辑</el-button>
					<el-button
						icon="el-icon-delete"
						type="text"
						size="small"
						sort="danger"
						@click="onDeleteDictItem(scope.row)"
					>删除</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
import { getDictsItemByType, deleteDictsItems } from "@/api/lib/api.js";
export default {
	name: "dictItemManagement",
	props: {
		type: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			total: 0,
			tableData: [],
		};
	},
	created() {
		this.getDictsItemByType();
	},
	methods: {
		getDictsItemByType() {
			if (this.type) {
				getDictsItemByType(this.type).then((res) => {
					if (res.code === 0) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			}
		},
		//编辑字典项
		onUpdateDictItem(row) {
			this.$emit("onEditDictItems", JSON.stringify(row));
		},
		//删除字典项
		onDeleteDictItem(row) {
			const h = this.$createElement;
			this.$msgbox({
				title: "消息",
				type: "warning",
				message: h("p", null, [
					h("span", null, "确定删除 "),
					h("i", { style: "color: #059370" }, row.name),
					h("span", null, " ？"),
				]),
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
			})
				.then(() => {
					deleteDictsItems(row.id).then((res) => {
						if (res.code === 0) {
							this.$message.success("删除成功");
							this.getDictsItemByType();
						} else {
							this.$message.error("删除失败");
						}
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						showClose: true,
						message: "已取消删除",
					});
				});
		},
	},
};
</script>

<style lang='scss' scoped>
.dictItem-management {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	padding: 16px 20px;
	box-sizing: border-box;
	.avatarImg {
		vertical-align: middle;
		border-radius: 50%;
	}
}

.theme-light {
	.dictItem-management {
		background: #ffffff;
		box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
	}
}
</style>